// Sign Up Flow ------------------------------------------------------------
let isSigningUp = false;
const signupDisplayDiv = document.querySelector("#signup-display");
const signupFormDiv = document.querySelector("#signup-form");
const signupSuccessDiv = document.querySelector("#signup-success");

// Step 1
const navSignUpButton = document.querySelector("#nav-signup-button");
const bannerSignUpButton = document.querySelector("#banner-signup-button");

// Step 2
const emailInput = document.querySelector("#form-email");
const joinBetaTester = document.querySelector("#beta-tester");
const cancelButton = document.querySelector("#form-cancel");
const submitButton = document.querySelector("#form-submit");

// Step 3
const registeredEmail = document.querySelector("#registered-email");
const doneButton = document.querySelector("#form-done");

// Sign up
const onSignupClicked = () => {
  signupDisplayDiv.classList.add("hidden");
  signupFormDiv.classList.remove("hidden");
  isSigningUp = true;
};
navSignUpButton.addEventListener("click", onSignupClicked);
bannerSignUpButton.addEventListener("click", onSignupClicked);

// Cancel Sign up
const onSignupCancelClicked = () => {
  signupFormDiv.classList.add("hidden");
  signupSuccessDiv.classList.add("hidden");
  signupDisplayDiv.classList.remove("hidden");
  emailInput.value = "";
  isSigningUp = false;
};
cancelButton.addEventListener("click", onSignupCancelClicked);

// Email validation
const isValidEmail = (mail) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
};
emailInput.addEventListener("input", () => {
  if (isValidEmail(emailInput.value)) {
    submitButton.disabled = false;
  } else {
    submitButton.disabled = true;
  }
});

// Sign up submit
const onSignupSubmitClicked = () => {
  signupFormDiv.classList.add("hidden");
  signupSuccessDiv.classList.remove("hidden");

  registeredEmail.textContent = emailInput.value;
  fetch("/api/subscribe/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: emailInput.value,
      beta_tester: joinBetaTester.checked,
    }),
  })
    .then((result) => {
      console.log("result", result);
    })
    .catch((error) => {
      console.error("error", error);
    });
};

submitButton.addEventListener("click", onSignupSubmitClicked);

// Sign up done
doneButton.addEventListener("click", onSignupCancelClicked);

// Banner Change -----------------------------------------------------
const signupBannerDiv = document.querySelector("#signup-banner");
const cryptoBannerDiv = document.querySelector("#crypto-banner");
const bannerNavArea = document.querySelector("#banner-nav");
const monetBannerNavButton = document.querySelector("#monet-banner-nav");
const cryptoBannerNavButton = document.querySelector("#crypto-banner-nav");

// Auto slide banner
let autoSlideTimeout = null;
const clearSlideTimeout = () => {
  // if (autoSlideTimeout) clearTimeout(autoSlideTimeout);
  // autoSlideTimeout = null;
};
const setSlideTimeout = () => {
  // if (!autoSlideTimeout) autoSlideTimeout = setTimeout(autoSlideBanner, 500000);
};

const slideBanner = () => {
  clearSlideTimeout();

  signupBannerDiv.classList.toggle("hidden");
  cryptoBannerDiv.classList.toggle("hidden");
  monetBannerNavButton.classList.toggle("active");
  cryptoBannerNavButton.classList.toggle("active");

  setSlideTimeout();
};

const autoSlideBanner = () => {
  if (!hoveringBanner && !isSigningUp) slideBanner();
};

setSlideTimeout();

let hoveringBanner = false;
const onMouseEnter = () => {
  hoveringBanner = true;
  clearSlideTimeout();
};
const onMouseLeave = () => {
  hoveringBanner = false;
  setSlideTimeout();
};
signupBannerDiv.addEventListener("mouseenter", onMouseEnter);
cryptoBannerDiv.addEventListener("mouseenter", onMouseEnter);
signupBannerDiv.addEventListener("mouseleave", onMouseLeave);
cryptoBannerDiv.addEventListener("mouseleave", onMouseLeave);

// Manually click to change banner
bannerNavArea.addEventListener("click", slideBanner);
